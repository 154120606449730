import { ThemeInfoRepresentation } from "libs/keycloak-admin-client/lib/defs/serverInfoRepesentation";

// --- DA Base Themes ---------------------------------------------------------

export const themeCategories = [
  "login",
  "account",
  "admin",
  "email",
  "common",
] as const;

export type ThemeCategory = (typeof themeCategories)[number];

export type BaseTheme = string;
export type BaseThemes = {
  [key in ThemeCategory]: BaseTheme[];
};

export type ThemeTypes = {
  [index: string]: ThemeInfoRepresentation[];
};

// --- END DA Base Themes -----------------------------------------------------
